/* eslint-disable no-console,no-undef */
import React from 'react';
import { MainContainer} from './styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActiveChallenge } from '../../../redux/actions';
// import { LoaderContainer } from "../../Content/styles";
import { isEmpty, isNull, isUndefined } from 'lodash';
// import Loading from '../../Loading';
import ActiveChallengesSub from './myActivityChallengeSub';
import SkeletonLoder from '../../common/skeletonLoder';

class ActiveChallengesV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeArray: []
    };
  }

  componentDidMount() {
    const { getActiveChallenge } = this.props;
    getActiveChallenge();
  }

  render() {
    const { activeChallenge, history, padding } = this.props;
    let activeChallengeShow = [];
    if (!isEmpty(activeChallenge) && activeChallenge && activeChallenge.length > 0) {
      activeChallengeShow = activeChallenge[0];
    }
    if(isUndefined(activeChallenge)){
      return(<SkeletonLoder/>)
    }
    return (
      <div>
        {activeChallengeShow ?
          <MainContainer paddingValue={padding === '1' ? '0px 25px 0px 0px' : '0px 0 15px 0'} marginValue={ !isEmpty(activeChallenge) && activeChallenge.length>0?"25px":"0px"}>
            { isNull(activeChallenge) || isUndefined(activeChallenge)?
              <SkeletonLoder width={"836px"} height={"230px"}/>:
              <ActiveChallengesSub activeChallenge={activeChallenge} history={history}/>
            }
          </MainContainer>
          : null}
      </div>
    )
  }
}
ActiveChallengesV2.propTypes = {
  history: PropTypes.object.isRequired,
  getActiveChallenge: PropTypes.func,
  activeChallenge: PropTypes.object,
  padding: PropTypes.string
};
const mapStateToProps = (state) => ({
  activeChallenge: state.wellnessDashboard.activeChallenge,
})
const mapDispatchToProps = (dispatch) => ({
  getActiveChallenge: () => dispatch(getActiveChallenge()),
})
export default connect(mapStateToProps, mapDispatchToProps)(ActiveChallengesV2);