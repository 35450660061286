/* eslint-disable no-console,no-undef */
import React from 'react';
import { SubContainerV2, ImageContainer, TextContainerV2, HeadingMain, MainContainer } from './styles';
import { convertDateInTimezone, getChallengeTimeToShowNew, convertMilesToKm, convertMonthFromDate } from "../../../utils/methods";
import moment from "moment";
import PropTypes from 'prop-types';
import { ImageUrl } from '../../../utils/constants';
import momentTZ from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import LazyImage from '../../common/LazyImage/LazyImage';

class MyActivityChallenges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeArray: [],
      time: []
    };
  }

  componentDidMount() {
    const { activeChallengeShow } = this.props;
    this.setState({
      time: getChallengeTimeToShowNew(activeChallengeShow, this.props)
    })
  }

  challengesPageRedirection = () => {
    const { history } = this.props;
    history.push('/challenges');
  }

  render() {
    const { activeChallenge, history, activeChallengeShow, indexCount, t } = this.props;
    const { time } = this.state;
    const DaysLeft = time.length > 0 && time.map((step) => step);
    const DistanceUnit = localStorage.getItem('DISTANCE');
    const lang = localStorage.getItem("lang");

    return (
      <React.Fragment>
        <MainContainer paddingValue={"0px 0px 0px 0px"}>
          {indexCount === 0 && <HeadingMain>
            <div>
              <div>{t("My Active Challenges")}</div>
              {activeChallenge.length > 1 && <div><div onClick={() => this.challengesPageRedirection()}>{t("See All >")}</div></div>}
            </div>
          </HeadingMain>}
          <SubContainerV2 onClick={() => history.push(`/challenges/${activeChallengeShow.id}`)} background={"1"}>
            <div>
              <ImageContainer width={"36.2%"} height={"190px"}>
                {activeChallengeShow && activeChallengeShow.image ? <img src={`${ImageUrl}/${activeChallengeShow.image}`} /> : null}
              </ImageContainer>
              <TextContainerV2 width={"63.8%"} color={activeChallengeShow.event_type === "Total Distance" ? "rgb(105, 194, 255)" : activeChallengeShow.event_type === "Total Calories" ? "rgb(246, 180, 121)" : "#32DBC6"} >
                <div>
                  <div>{activeChallengeShow.title}</div>
                  <div>
                    <div>{activeChallengeShow.event_type === "Total Distance" ? <LazyImage src={ImageUrl + "/images/NewDashboardV2/DistanceIcon.png"} alt="activity" /> : activeChallengeShow.event_type === "Total Calories" ? <LazyImage src={ImageUrl + "/images/NewDashboardV2/CaloriesIcon.png"} alt="activity" /> : <LazyImage src={ImageUrl + "/images/NewDashboardV2/StepIcon.png"} alt="activity" />}</div>
                  </div>
                </div>

                <div>
                  <div>{ (lang !== 'fr') ? (convertMonthFromDate(moment(convertDateInTimezone(activeChallengeShow && activeChallengeShow.start_date)._d).format('MMM DD, YYYY'), this.props)) : (convertMonthFromDate(moment(convertDateInTimezone(activeChallengeShow && activeChallengeShow.start_date)._d).format('DD MMM YYYY'), this.props))} - { (lang !== 'fr') ?  (convertMonthFromDate(moment(convertDateInTimezone(activeChallengeShow && activeChallengeShow.end_date)._d).format('MMM DD, YYYY'), this.props)) : (convertMonthFromDate(moment(convertDateInTimezone(activeChallengeShow && activeChallengeShow.end_date)._d).format('DD MMM YYYY'), this.props))}</div>
                  <div />
                </div>

                <div>
                  <div className="challenge-user">
                    <div className="user-info">
                      {activeChallengeShow.user_list.map((user, index) => (
                        (index < 2) ? <span key={index}> <img onError={this.fallBack} src={`${ImageUrl}/${user.profile_image}`} /></span> : null
                      ))
                      }
                      {(activeChallengeShow.user_list && activeChallengeShow.user_list.length > 2) ?
                        <span className="circle">
                          {activeChallengeShow.user_list.length - 2}+
                        </span>
                        : null
                      }
                      {
                        (activeChallengeShow.challenge_status === 'over') ?
                          <span className={"user-list"}>
                            {activeChallengeShow.user_list && activeChallengeShow.user_list.length > 1 ? 'Participants have completed'
                              : 'Participant has completed'}</span>
                          : <span className={"user-list"}>{activeChallengeShow.user_list.length === 0 ? t('No Participants') : activeChallengeShow.user_list.length > 1 ? activeChallengeShow.is_dummy_challenge === 1 ? '6320' : t('Participants') : activeChallengeShow.is_dummy_challenge === 1 ? '6320' : t('Participant')}</span>
                      }
                    </div>
                  </div>
                  {activeChallengeShow.challenge_status == "over" ? null :
                    <div>{(DaysLeft == 'Starts Today' || DaysLeft == 'Last Day') ? DaysLeft : (convertDateInTimezone(activeChallengeShow.start_date)._d <= momentTZ()._d ? `${DaysLeft} ${t("Remaining")}` : `${DaysLeft} ${t("to Start")}`)}</div>
                  }
                </div>

                <div>
                  <div>{activeChallengeShow.event_type === "Total Distance" ? t("My Total Distance") : activeChallengeShow.event_type === "Total Calories" ? t("My Total Calories") : t("My Total Steps")}</div>
                  <div>
                    {activeChallengeShow.event_type === "Total Distance" ?DistanceUnit === "KILOMETER"?convertMilesToKm(activeChallengeShow.distance): Math.round(activeChallengeShow.distance) : activeChallengeShow.event_type === "Total Calories" ? Math.round(activeChallengeShow.calories) : Math.round(activeChallengeShow.steps)}
                  </div>
                </div>
              </TextContainerV2>
            </div>

          </SubContainerV2>
        </MainContainer>
      </React.Fragment>
    )
  }
}
MyActivityChallenges.propTypes = {
  history: PropTypes.object.isRequired,
  getActiveChallenge: PropTypes.func,
  activeChallenge: PropTypes.object,
  padding: PropTypes.string,
  activeChallengeShow: PropTypes.object,
  indexCount: PropTypes.number,
  t: PropTypes.func
};

export default (withTranslation()(MyActivityChallenges));