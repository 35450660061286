/* eslint-disable no-console,no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import ActiveBehaviorChallenge from './myActivtyChallengeBehavior';
import MyActivityChallenges from './myActivityAllChallenges';

class ActiveChallengesSub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeArray: [],
    };
  }

  challengesPageRedirection = () => {
    const { history } = this.props;
    history.push('/challenges');
  }

  render() {
    const { activeChallenge, history } = this.props;
    let activeChallengesData = activeChallenge;
    if (activeChallenge.length > 1) {
      activeChallengesData = activeChallenge && activeChallenge.slice(0, 1);
    }
    return (
      <React.Fragment>
        {activeChallengesData && activeChallengesData.map((activeChallengeShow, index) => (
          (activeChallengeShow.challenge_type === "behavior" ?
            (activeChallengeShow.is_joined && <ActiveBehaviorChallenge activeChallengeShow={activeChallengeShow} history={history} indexCount={index}  challengeLength={activeChallenge}/>) :
            <MyActivityChallenges key={index} activeChallengeShow={activeChallengeShow} activeChallenge={activeChallenge} indexCount={index} history={history}/>
          )))}
      </React.Fragment>
    )
  }
}
ActiveChallengesSub.propTypes = {
  history: PropTypes.object.isRequired,
  getActiveChallenge: PropTypes.func,
  activeChallenge: PropTypes.object,
  padding: PropTypes.string
};

export default ActiveChallengesSub;